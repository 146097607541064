import React from 'react';
import ContactSection from '../../components/Main/ContactSection';

const ContactScreen = () => {
  return (
    <>
      <ContactSection />
    </>
  )
}

export default ContactScreen;