export const MAKE_REQUEST='MAKE_REQUEST'
export const FAIL_REQUEST='FAIL_REQUEST'

export const GET_USER_LIST='GET_USER_LIST'
export const DELETE_USER='DELETE_USER'
export const ADD_USER='ADD_USER'
export const UPDATE_USER='UPDATE_USER'
export const GET_USER_OBJ='GET_USER_OBJ'

export const CONTACT_MESSAGE = "CONTACT_MESSAGE"
export const NEWSLETER_EMAIL = 'NEWSLETER_EMAIL'